"use client";

import { InfiniteMovingCards } from "@/components/infinite-moving-cards";
import React from "react";

export function FeaturedOn() {
  return (
    <div data-aos="fade-up" className="h-auto mb-10 mx-10 rounded-md flex flex-col md:flex-row antialiased items-center justify-center relative overflow-hidden">
      <div className="relative w-full md:w-[30%] lg:w-[20%] rounded-lg flex-shrink-0 p-4">
  <h1 className="tracking-wider text-gray-800 font-bold text-md uppercase mb-2">Featured In & Tech</h1>
  <p className="text-sm text-gray-500 mb-4">Discover the platforms that have highlighted our innovative product and explore the cutting-edge technologies powering its success.</p>
  <ul className="flex flex-row justify-between items-center space-x-1">
    <li className="flex items-center justify-center"><img src="/Next.js.png" alt="Next.js" className="w-10 h-10 object-contain" /></li>
    <li className="flex items-center justify-center"><img src="/Tailwind CSS.png" alt="Tailwind CSS" className="w-10 h-10 object-contain" /></li>
    <li className="flex items-center justify-center"><img src="/Sentry.png" alt="Sentry" className="w-16 h-16 object-contain" /></li>
    <li className="flex items-center justify-center"><img src="/Razorpay.png" alt="Razorpay" className="w-16 h-16 object-contain" /></li>
  </ul>
</div>

      <InfiniteMovingCards
        items={testimonials}
        direction="right"
        speed="slow"
      />
    </div>
  );
}

const testimonials = [
    {
        img: new URL(
            '/public/Hashnode Ai.png',
            import.meta.url,
        ).toString(),
        title: "Hashnode",
        link:"https://hashnode.com/@sanketbagad?ref=botbye-ai"
      },
      {
        img: new URL(
            '/public/toollist.png',
            import.meta.url,
        ).toString(),
        title: "Toollist.ai",
        link:"https://toollist.ai/product/botbyte-ai?ref=botbyte-ai"
      },
      {
        img: new URL(
            '/public/Product Hunt.svg',
            import.meta.url,
        ).toString(),
        title: "Product Hunt",
        link: "https://www.producthunt.com/posts/botbyte-ai?ref=botbyte-ai"
      },
      {
        img: new URL(
            '/public/PeerlistLogo.png',
            import.meta.url,
        ).toString(),
        title: "Peerlist",
        link: "https://peerlist.io/sanketbagad/project/botbyte-ai?ref=botbyte-ai"
      },
      {
        img: new URL(
            '/public/sw-logo.webp',
            import.meta.url,
        ).toString(),
        title: "Sass Worthy",
        link: "https://www.saasworthy.com/compare/botbyte-ai?pIds=48790?ref=botbyte-ai"
      },
      {
        img: new URL(
            '/public/devhunt.svg',
            import.meta.url,
        ).toString(),
        title: "Devhunt",
        link: "https://devhunt.org/tool/bytebot-ai?ref=botbyte-ai"
      },
      {
        img: new URL(
            '/public/launched_logo30.png',
            import.meta.url,
        ).toString(),
        title: "Launched.io",
        link: "https://launched.io/startup/10231-botbyte-ai?ref=botbyte-ai"
      },
      {
        img: new URL(
            '/public/Raiday-Logo-cropped-150x150-2.webp',
            import.meta.url,
        ).toString(),
        title: "Raiday",
        link: "https://raiday.ai/blog/ai-tool/botbyte-ai?ref=botbyte-ai"
      },
      {
        img: new URL(
            '/public/resourcefyi.png',
            import.meta.url,
        ).toString(),
        title: "Resourcefyi",
        link: "https://resource.fyi/products/botbyte-ai?ref=botbyte-ai"
      },
      {
        img: new URL(
            '/public/Medium.png',
            import.meta.url,
        ).toString(),
        title: "Medium",
        link: "https://medium.com/@alencolins/why-use-botbyte-ai-for-your-website-d3c52c14229e"
      },
      {
        img: new URL(
            '/public/appsumo.svg',
            import.meta.url,
        ).toString(),
        title: "Appsumo",
        link: "https://medium.com/@alencolins/why-use-botbyte-ai-for-your-website-d3c52c14229e"
      },
];
