"use client";

import { cn } from "@/lib/utils";
import Link from "next/link";
import React, { useEffect, useState } from "react";

export const InfiniteMovingCards = ({
  items,
  direction = "left",
  speed = "fast",
  pauseOnHover = true,
  className,
}: {
  items: {
    title: string;
    img: string;
    link: string;
  }[];
  direction?: "left" | "right";
  speed?: "fast" | "normal" | "slow";
  pauseOnHover?: boolean;
  className?: string;
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const scrollerRef = React.useRef<HTMLUListElement>(null);

  useEffect(() => {
    addAnimation();
  }, []);
  const [start, setStart] = useState(false);
  function addAnimation() {
    if (containerRef.current && scrollerRef.current) {
      const scrollerContent = Array.from(scrollerRef.current.children);

      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        if (scrollerRef.current) {
          scrollerRef.current.appendChild(duplicatedItem);
        }
      });

      getDirection();
      getSpeed();
      setStart(true);
    }
  }
  const getDirection = () => {
    if (containerRef.current) {
      if (direction === "left") {
        containerRef.current.style.setProperty(
          "--animation-direction",
          "forwards"
        );
      } else {
        containerRef.current.style.setProperty(
          "--animation-direction",
          "reverse"
        );
      }
    }
  };
  const getSpeed = () => {
    if (containerRef.current) {
      if (speed === "fast") {
        containerRef.current.style.setProperty("--animation-duration", "20s");
      } else if (speed === "normal") {
        containerRef.current.style.setProperty("--animation-duration", "40s");
      } else {
        containerRef.current.style.setProperty("--animation-duration", "80s");
      }
    }
  };
  return (
<div
  ref={containerRef}
  className={cn(
    "scroller relative z-20 max-w-7xl overflow-hidden [mask-image:linear-gradient(to_right,transparent,white_20%,white_80%,transparent)]",
    className
  )}
>
  <ul
    ref={scrollerRef}
    className={cn(
      "flex min-w-full shrink-0 gap-4 py-4 w-max flex-nowrap",
      start && "animate-scroll",
      pauseOnHover && "hover:[animation-play-state:paused]"
    )}
  >
    {items.map((item, i) => (
      <Link href={item.link} target="_blank" rel="noopener noreferrer"
        key={i}
        className="w-[125px] h-[160px] relative rounded-lg border bg-gray-50 shadow-lg flex-shrink-0 p-2"
      >
        <blockquote className="flex h-full justify-evenly flex-col">
          <img src={item.img} alt={item.title} className="rounded-lg object-contain w-full h-[70%]" />
          <p className=" text-center text-xs font-semibold tracking-wider uppercase">
         {item.title}</p>
        </blockquote>
      </Link>
    ))}
  </ul>
</div>

  );
};
